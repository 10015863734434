@import './../../styles/variables';

.progressBar {
    display: flex;
    position: relative;
    align-items: center;
    padding: 0.5rem 0;
}

.dot {
    position: relative;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid $primary-hl9;
    border-radius: 1.4rem;
    z-index: 15;
}

.innerDot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background-color: $primary-hl2;
    z-index: 15;
}

.isActive {
    background-color: $primary;
}

.line {
    display: inline-block;
    width: 5.5rem;
    height: 2px;
    transform: translateY(0);
    background-color: $primary-hl9;
}

.progressLineWrapper {
    display: block;
    position: absolute;
    width: 5.5rem;
    height: 1rem;
    overflow: hidden;
}

.stepOne {
    left: 1.4rem;
}

.stepTwo {
    left: 8.5rem;
}

.progressLine {
    display: inline-block;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 5.5rem;
    height: 2px;
    transform: translateY(-50%) translateX(-100%);
    z-index: 10;
    background-color: $primary-hl1;
}