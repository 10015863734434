/**

    Buttons

*/

button,
.btn {
    background: $primary-hl1;
    color: white;
    border: 0;
    padding: 2.2rem 4.5rem;
    border-radius: 0.7rem;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.2em;
    font-size: 1.6rem;
    cursor: pointer;
    box-shadow: 0 0 0 3px rgba($color: $primary-hl1, $alpha: 0);
    text-decoration: none;
    transition: 0.2s ease all;

    &:hover {
        background-color: darken($primary-hl1, 5%);
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba($color: $primary-hl2, $alpha: 0.4);
    }

    &[disabled]{
        pointer-events: none;
        cursor: not-allowed;
        background-color: $grey;
    }

    svg.arrow-right {
        position: relative;
        width: 1.3rem;
        height: 1.3rem;
        top: 0.05rem;
    }

    svg.loader {
        position: relative;
        width: 2.5rem;
        height: 1.5rem;
        top: 0.05rem;
    }

    svg.check-circ {
        position: relative;
        width: 1.3rem;
        height: 1.3rem;
        top: 0.03rem;
    }

    &.is-loading {
        opacity: 0.6;
        cursor: progress;
    }

    &.is-success {
        position: relative;
        background-color: lighten($primary-hl1, 10%);
        box-shadow: 0 0 10px 5px rgba($color: $primary-hl1, $alpha: 0.4);

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -15rem;
            width: 12.5rem;
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,0) 26%, rgba(255,255,255,0) 49%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%); 
            opacity: 0.05;
            transform: skewX(-15deg);
            animation-name: swipe;
            animation-duration: 0.7s;
            animation-timing-function: cubic-bezier(.98,.16,.54,.64);
        }
    }

    &.reverse {
        border: 1px solid $light-grey;
        background-color: transparent;
        color: $light-grey;

        &:hover {
            color: darken($light-grey, 10%);
            border-color: darken($light-grey, 10%); 
        }
    }
}

.button-group {
    display: flex;
    flex-wrap: wrap;

    > * {
        margin: 1rem;
    }
}

@keyframes swipe {
    0% {
        left: -15rem;
    }
    100% {
        left: 100%;
    }
}