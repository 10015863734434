@import '../../../styles/variables';

/*
* Edit Styles
*/
.inputGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem 3rem;

    > * > * {
        width: 100%;
    }
}

/*
* Preview Styles
*/
.names {
    list-style-type: none;
}

.name {
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.1;
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
    white-space: pre-line;

    &:not(:last-child){
        margin-bottom: 1rem;

        &::after {
            content: '';
            display: block;
            width: 5rem;
            height: 2px;
            background-color: lighten($primary-hl2, 25%);
            margin: 2.5rem auto 0;
        }
    }
}
