/**

    Preview Wrapper

*/

@import '../../styles/variables.scss';

.previewWrapper {
    position: relative;
    display: block;
    width: 626px;
    height: 626px;
    margin: 0 auto;
    transition: 0.1s ease transform;
}

.previewContent {
    position: relative;
    display: block;
    background-color: white;
    z-index: 5;
    box-sizing: border-box;
    width: 626px;
    height: 626px;
    margin: 0 auto;
    padding: 92px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.previewBorder {
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    border: 2px solid $primary-hl2;
}

.previewShadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    z-index: -1;
    box-shadow: 0 4px 80px 30px rgba(0, 0, 0, 0.1);
}

.btnWrapper {
    text-align: center;
    margin-top: 6rem;

    button {
        min-width: 28.1rem;
    }
}