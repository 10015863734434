@import './../../styles/variables';

.backLink {
    display: inline-block;
    color: $light-grey;
    text-decoration: none;
    font-weight: 900;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    transition: 0.2s ease all;

    &:hover,
    &:focus {
        color: darken($light-grey, 10%);
    }

    svg,
    path {
        fill: currentColor;
    }
}

.text {
    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.arrow {
    margin-right: 1.4rem;
    position: relative;
    transform: translateY(3px);
}