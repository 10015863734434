.header {
    margin-bottom: 10.3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
    z-index: 5;

    @media only screen and (max-width:768px) {
        margin-bottom: 4rem;
        grid-template-columns: auto 1fr;
    }

    > * {
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
            justify-content: flex-start;
        }

        &:last-child {
            justify-content: flex-end;
        }
    }
}

.logo {
    text-align: center;
    margin-bottom: 4.7rem;
}

.title {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.2em;
    position: relative;
}