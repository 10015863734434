@import './../../styles/variables';

.currentLevel {
    display: inline-block;
    line-height: 1.2;
    color: $primary-hl2;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.2rem;
    letter-spacing: 0.2em;
}