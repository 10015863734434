/**

    Pick A Donor Level

*/

@import './../../styles/variables';

.wrapper {
    width: 100%;
    max-width: 47rem;
    margin: 0 auto;
}

.links {
    &:not(:last-child){
        margin-bottom: 1.4rem;
    }
}

.btn {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    font-size: 1.6rem;

    &:not(:last-child){
        margin-bottom: 1.4rem;
    }

    .btnTitle {
        font-weight: bold;
    }

    .btnPrice {
        letter-spacing: 0.2em;
    }

    a {
        padding: 3rem 4.2rem;
        color: white;
        display: flex;
        background-color: $primary;
        justify-content: space-between;
        width: 100%;
        text-decoration: none;
        border-radius: 0.7rem;
        box-shadow: 0 0 0 3px rgba($color: $primary, $alpha: 0);
        transition: 0.2s ease all;


        @media only screen and (max-width:768px) {
            padding: 2.5rem 2rem;
            font-size: 1.4rem;
        }

        &:hover {
            background-color: darken($primary, 5%);
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 3px rgba($color: $primary-hl2, $alpha: 0.4);
        }
    }

    &:nth-of-type(1) a {
        background-color: $primary-hl2;

        &:hover {
            background-color: darken($primary-hl2, 5%);
        }
    }
    
    &:nth-of-type(2) a {
        background-color: $primary-hl1;

        &:hover {
            background-color: darken($primary-hl1, 5%);
        }
    }

}