/**

    Inputs

*/

input,
textarea {
    margin: 0;
    padding: 2.7rem 4rem;
    border-radius: 0.7rem;
    outline: 0;
    border: 1px solid $grey;
    font-weight: bold;
    box-shadow: 0 0 0 2px rgba($color: $primary-hl2, $alpha: 0);
    transition: box-shadow 0.3s ease;

    @media only screen and (max-width: 768px) {
        padding: 2rem 2rem;
    }

    &::placeholder {
        font-weight: bold;
        color: #BEBEBE;
        opacity: 1;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba($color: hsl(282, 85%, 68%), $alpha: 0.17);
    }
}

textarea {
    resize: none;
}

.file-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 4rem;
    border-radius: 0.7rem;
    outline: 0;
    border: 2px dashed $grey;
    font-weight: bold;
    transition: border-color 0.3s ease;
    text-align: center;
    min-height: 20rem;
    cursor: pointer;

    img {
        width: 100%;
        height: auto;
        max-width: 10rem;
        max-height: 10rem;
    }

    svg {
        max-width: 3rem;
        color: lighten($dark-grey, 20%);
        margin-bottom: 0.5rem;
    }

    .small-text {
        color: lighten($dark-grey, 20%);
        font-weight: normal;
        font-size: .8em;
    }

    @media only screen and (max-width: 768px) {
        padding: 2rem 2rem;
    }

    &:hover,
    &:focus {
        border-color: $dark-grey;
    }

    &.drag-active {
        outline: none;
        border-color: $primary-hl2;
    }
}