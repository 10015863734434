@import '../../../styles/variables';
/*
* Edit Styles
*/


/*
* Preview Styles
*/
.main {
    svg, img {
        width: 100%;
        max-width: 11.6rem;
        height: auto;
        margin: 0 auto;
    }

    img {
        position: relative;
        transform: translateX(-10px);
    }

    .customLogo {
        width: 100%;
        height: auto;
        max-width: 10rem;
        max-height: 10rem;
        transform: translateX(0);
    }
}

.name {
    font-size: 2.8rem;
    margin-top: 2rem;
    margin-bottom: 0rem;
    line-height: 1.1;
    white-space: pre-line;
}

.inMemoryMessage {
    font-style: italic;
    font-size: 1.8rem;
    color: $dark-grey;
    white-space: pre-line;
}

.message {
    color: $dark-grey;
    font-size: 1.8rem;
    line-height:  1.44;
    text-align: center;
    margin-top: 2.8rem;
    white-space: pre-line;
}