/**

    Variables

*/

// Colors
$primary: hsl(282, 59%, 34%);
$primary-hl1: hsl(282, 34%, 47%);
$primary-hl2: hsl(282, 30%, 60%);
$primary-hl9: hsl(284, 29%, 90%);
$light-grey: hsl(0, 0%, 80%);
$grey: hsl(0, 0%, 78%);
$dark-grey: hsl(0, 0%, 37%);
$black: hsl(0, 0%, 21%);

// Font
$font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;