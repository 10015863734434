@font-face {
	font-family: 'Proxima Nova';
	src: url('./../fonts/proxima-nova/ProximaNova-Black.eot');
	src: url('./../fonts/proxima-nova/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/proxima-nova/ProximaNova-Black.woff') format('woff'),
		url('./../fonts/proxima-nova/ProximaNova-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./../fonts/proxima-nova/ProximaNova-BlackIt.eot');
	src: url('./../fonts/proxima-nova/ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/proxima-nova/ProximaNova-BlackIt.woff') format('woff'),
		url('./../fonts/proxima-nova/ProximaNova-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./../fonts/proxima-nova/ProximaNova-Bold.eot');
	src: url('./../fonts/proxima-nova/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/proxima-nova/ProximaNova-Bold.woff') format('woff'),
		url('./../fonts/proxima-nova/ProximaNova-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./../fonts/proxima-nova/ProximaNova-BoldIt.eot');
	src: url('./../fonts/proxima-nova/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/proxima-nova/ProximaNova-BoldIt.woff') format('woff'),
		url('./../fonts/proxima-nova/ProximaNova-BoldIt.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}


@font-face {
	font-family: 'Proxima Nova';
	src: url('./../fonts/proxima-nova/ProximaNova-Regular.eot');
	src: url('./../fonts/proxima-nova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/proxima-nova/ProximaNova-Regular.woff') format('woff'),
		url('./../fonts/proxima-nova/ProximaNova-Regular.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./../fonts/proxima-nova/ProximaNova-RegularIt.eot');
	src: url('./../fonts/proxima-nova/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/proxima-nova/ProximaNova-RegularIt.woff') format('woff'),
		url('./../fonts/proxima-nova/ProximaNova-RegularIt.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./../fonts/proxima-nova/ProximaNova-Light.eot');
	src: url('./../fonts/proxima-nova/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/proxima-nova/ProximaNova-Light.woff') format('woff'),
		url('./../fonts/proxima-nova/ProximaNova-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('./../fonts/proxima-nova/ProximaNova-LightIt.eot');
	src: url('./../fonts/proxima-nova/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
		url('./../fonts/proxima-nova/ProximaNova-LightIt.woff') format('woff'),
		url('./../fonts/proxima-nova/ProximaNova-LightIt.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}
