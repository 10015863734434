@import './styles/reset';
@import './styles/font';
@import './styles/variables';
@import './styles/input';
@import './styles/buttons';
@import './styles/modal';

html {
    font-size: 62.5%;
}

body {
    font-family: $font-family;
    font-size: 1.6rem;
    color: $black;
    overflow-y: scroll;
}

.wrapper {
    max-width: 970px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 13rem 2.5rem;

    @media only screen and (max-width:768px) {
        padding: 3rem 2rem 10rem;
    }
}

.primary {
    color: $primary;
}

.primary-hl1 {
    color: $primary-hl1;
}