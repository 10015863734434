@import '../../../styles/variables';

/*
* Edit Styles
*/

.inputGroup {
    display: flex;
    flex-direction: column;

    &:not(:last-child){
        &::after {
            content: '';
            display: block;
            width: 5rem;
            margin: 3.6rem auto 2rem;
            height: 1px;
            background-color: lighten($grey, 10%);
        }
    }

    > * + * {
        margin-top: 1.4rem;
    }
}


/*
* Preview Styles
*/

.donors {
    list-style-type: none;
}

.donor {
    &:not(:last-child){
        margin-bottom: 4rem;

        &::after {
            content: '';
            display: block;
            width: 5rem;
            height: 2px;
            background-color: lighten($primary-hl2, 25%);
            margin: 4rem auto 0;
        }
    }
}

.name {
    font-size: 2.8rem;
    margin-top: 1rem;
    margin-bottom: 0rem;
    line-height: 1.1;
    white-space: pre-line;
}

.message {
    color: $dark-grey;
    font-size: 1.8rem;
    line-height:  1.44;
    text-align: center;
    margin-top: 1rem;
    white-space: pre-line;
}