.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.ReactModalPortal {
    position: relative;
    z-index: 9999;

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #ccc;
        background: #fff;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        border-radius: 4px;
        outline: none;
        padding: 20px;
        width: 90%;
        height: 90%;
        max-width: 71.2rem;
        max-height: 40rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 2rem;
        text-align: center;
        padding: 2.5rem 7.5rem;

        .icon {
            margin-bottom: 2.4rem;
            color: $primary-hl1;
        }

        strong {
            font-weight: 900;
        }

        .close-button {
            display: flex;
            align-items: center;
            position: absolute;
            top: 2.5rem;
            left: 2.5rem;
            background-color: transparent;
            text-align: left;
            color: $light-grey;
            padding: 0;
            font-weight: 900;
            font-size: 1.2rem;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            transition: 0.2s ease all;

            &:hover,
            &:focus {
                color: darken($light-grey, 10%);
            }

            svg,
            path {
                fill: currentColor;
                margin-right: 1rem;
            }
        }

        .btn {
            margin-top: 3.4rem;
            font-size: 1.4rem;
            padding: 1.6rem 3.2rem;
        }
    }
}
