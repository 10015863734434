/**

    Edit Wrapper

*/

.editContent {
    display: flex;
    flex-direction: column;
    max-width: 72rem;
    margin: 0 auto;

    > * + * {
        margin-top: 1.4rem;
    }

    > * > * {
        width: 100%;
    }
}

.btnWrapper {
    text-align: center;
    margin-top: 6rem;
}